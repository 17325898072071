import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {User} from '../../factory/models/authModel';
import {EmployeeRecordService} from '../../employee-record/services/employee-record.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private user: User;
  private employee: any;

  constructor(private service: EmployeeRecordService) {
    this.user = JSON.parse(localStorage.getItem('userData'));
  }

  getRoleName(): string {
    if (this.user.roles.includes('admin')) {
      return 'admin';
    }
    else if (this.user.roles.includes('hr')) {
      return 'hr';
    }
    return null;
  }

  hasProfile() {
    return this.user?.hasProfile
  }

}
