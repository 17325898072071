import { Component, OnInit } from '@angular/core';
import {DashboardService} from './dashboard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  appraisalCount: number;
  registeredEmployeeCount: number;
  myDeptStaffCount: number;
  recentlyRegisteredEmployees: any[];

  date: Date = new Date();

  visitSaleChartData = [{
    label: 'CHN',
    data: [20, 40, 15, 35, 25, 50, 30, 20],
    borderWidth: 1,
    fill: false,
  },
  {
    label: 'USA',
    data: [40, 30, 20, 10, 50, 15, 35, 40],
    borderWidth: 1,
    fill: false,
  },
  {
    label: 'UK',
    data: [70, 10, 30, 40, 25, 50, 15, 30],
    borderWidth: 1,
    fill: false,
  }];

  visitSaleChartLabels = ['2013', '2014', '2014', '2015', '2016', '2017'];

  visitSaleChartOptions = {
    responsive: true,
    legend: false,
    scales: {
        yAxes: [{
            ticks: {
                display: false,
                min: 0,
                stepSize: 20,
                max: 80
            },
            gridLines: {
              drawBorder: false,
              color: 'rgba(235,237,242,1)',
              zeroLineColor: 'rgba(235,237,242,1)'
            }
        }],
        xAxes: [{
            gridLines: {
              display:false,
              drawBorder: false,
              color: 'rgba(0,0,0,1)',
              zeroLineColor: 'rgba(235,237,242,1)'
            },
            ticks: {
                padding: 20,
                fontColor: '#9c9fa6',
                autoSkip: true,
            },
            categoryPercentage: 0.4,
            barPercentage: 0.4
        }]
      }
  };

  visitSaleChartColors = [
    {
      backgroundColor: [
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
      ],
      borderColor: [
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
        'rgba(154, 85, 255, 1)',
      ]
    },
    {
      backgroundColor: [
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
      ],
      borderColor: [
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
        'rgba(254, 112, 150, 1)',
      ]
    },
    {
      backgroundColor: [
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
      ],
      borderColor: [
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
        'rgba(177, 148, 250, 1)',
      ]
    },
  ];

  trafficChartData = [
    {
      data: [30, 30, 40],
    }
  ];

  trafficChartLabels = ['Search Engines', 'Direct Click', 'Bookmarks Click'];

  chartOptions = {
    responsive: true,
    animation: {
      animateScale: true,
      animateRotate: true
    },
    legend: false,
  };

  trafficChartColors = [
    {
      backgroundColor: [
        'rgba(177, 148, 250, 1)',
        'rgba(254, 112, 150, 1)',
        'rgba(132, 217, 210, 1)',
        'rgba(239, 195, 56, 1)',
        'rgba(103, 230, 141, 1)',
        'rgba(63, 81, 181, 1)',
        'rgba(255, 87, 34, 1)',
        'rgba(255, 152, 0, 1)',
        'rgba(121, 85, 72, 1)',
        'rgba(33, 150, 243, 1)',
        'rgba(255, 69, 96, 1)',
        'rgba(48, 63, 159, 1)',
        'rgba(0, 150, 136, 1)',
        'rgba(156, 39, 176, 1)',
        'rgba(233, 30, 99, 1)',
        'rgba(0, 188, 212, 1)',
        'rgba(63, 63, 191, 1)',
        'rgba(76, 175, 80, 1)',
        'rgba(255, 202, 40, 1)',
        'rgba(255, 87, 34, 1)',
        'rgba(96, 125, 139, 1)',
        'rgba(255, 23, 68, 1)',
        'rgba(0, 96, 100, 1)',
        'rgba(121, 85, 72, 1)',
        'rgba(63, 81, 181, 1)'
      ],
      borderColor: [
        'rgba(177, 148, 250, .2)',
        'rgba(254, 112, 150, .2)',
        'rgba(132, 217, 210, .2)',
        'rgba(239, 195, 56, .2)',
        'rgba(103, 230, 141, .2)',
        'rgba(63, 81, 181, .2)',
        'rgba(255, 87, 34, .2)',
        'rgba(255, 152, 0, .2)',
        'rgba(121, 85, 72, .2)',
        'rgba(33, 150, 243, .2)',
        'rgba(255, 69, 96, .2)',
        'rgba(48, 63, 159, .2)',
        'rgba(0, 150, 136, .2)',
        'rgba(156, 39, 176, .2)',
        'rgba(233, 30, 99, .2)',
        'rgba(0, 188, 212, .2)',
        'rgba(63, 63, 191, .2)',
        'rgba(76, 175, 80, .2)',
        'rgba(255, 202, 40, .2)',
        'rgba(255, 87, 34, .2)',
        'rgba(96, 125, 139, .2)',
        'rgba(255, 23, 68, .2)',
        'rgba(0, 96, 100, .2)',
        'rgba(121, 85, 72, .2)',
        'rgba(63, 81, 181, .2)'
      ]
    }
  ];

  private departmentAnalytics: any;
  public deptNameArray: string[] = [];
  public deptCountArray: any = [];
  departmentArray: any = [];
  sexCountArray: any = [];
  sexNameArray: any = [];
  sexArray: any = [];
  private sexAnalytics: any[] = [];
  contractArray: any = [];
  contractCountArray: any = [];
  contractNameArray: any = [];
  private contractAnalytics: any[];

  toggleProBanner(event) {
    console.log('123');
    event.preventDefault();
    document.querySelector('body').classList.toggle('removeProbanner');
  }

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.dashboardService.getAppraisalCount().subscribe(
      count => this.appraisalCount = count,
      error => console.error('Error fetching appraisal count:', error)
    );

    this.dashboardService.getRegisteredEmployeeCount().subscribe(
      count => this.registeredEmployeeCount = count,
      error => console.error('Error fetching registered employee count:', error)
    );

    this.dashboardService.getMyDeptStaffCount().subscribe(
      count => this.myDeptStaffCount = count,
      error => console.error('Error fetching department staff count:', error)
    );

    this.dashboardService.getRecentlyRegisteredEmployees().subscribe(
      employees => this.recentlyRegisteredEmployees = employees,
      error => console.error('Error fetching recently registered employees:', error)
    );

    this.dashboardService.getAnalytics('department').subscribe(
      departmentAnalytics => {
        this.departmentAnalytics = departmentAnalytics
         this.departmentArray = Object.entries(departmentAnalytics).map(([name, count]) => ({ name, count }));

        this.deptCountArray = [
          {
            data: Object.values(departmentAnalytics),
          }
        ]
        this.deptNameArray = Object.keys(departmentAnalytics)
        console.log(this.departmentAnalytics);
      },
      error => console.error('Error fetching recently department analytics:', error)
    );

    this.dashboardService.getAnalytics('sex').subscribe(
      sexAnalytics => {
        this.sexAnalytics = sexAnalytics
         this.sexArray = Object.entries(sexAnalytics).map(([name, count]) => ({ name, count }));

        this.sexCountArray = [
          {
            data: Object.values(sexAnalytics),
          }
        ]
        this.sexNameArray = Object.keys(sexAnalytics)
        console.log(this.sexAnalytics);
      },
      error => console.error('Error fetching recently sex analytics:', error)
    );

    this.dashboardService.getAnalytics('contract_type').subscribe(
      contractAnalytics => {
        this.contractAnalytics = contractAnalytics
         this.contractArray = Object.entries(contractAnalytics).map(([name, count]) => ({ name, count }));

        this.contractCountArray = [
          {
            data: Object.values(contractAnalytics),
          }
        ]
        this.contractNameArray = Object.keys(contractAnalytics)
        console.log(this.contractAnalytics);
      },
      error => console.error('Error fetching recently contract analytics:', error)
    );
  }
}
