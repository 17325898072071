import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private baseUrl = 'https://mono.specvista.com/mojechr/api/v1';

  constructor(private http: HttpClient) { }

  getAppraisalCount(): Observable<number> {
    const url = `${this.baseUrl}/dashboard/appraisal/count`;
    return this.http.get<number>(url);
  }

  getRegisteredEmployeeCount(): Observable<number> {
    const url = `${this.baseUrl}/employee-data/list`;
    return this.http.get<number>(url);
  }

  getMyDeptStaffCount(): Observable<number> {
    const userData = JSON.parse(localStorage.getItem('userData'))
    const url = `${this.baseUrl}/dashboard/employee/dept/list`;
    return this.http.get<number>(url, {params: {
        user_id: userData.id
      }});
  }

  getRecentlyRegisteredEmployees(): Observable<any[]> {
    const url = `${this.baseUrl}/employee-data/list`;
    return this.http.get<any[]>(url);
  }

  getAnalytics(category) {
    const url = `${this.baseUrl}/dashboard/employee/category`;
    return this.http.get<any[]>(url, {params: {category}});
  }
}
