import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the token from wherever you store it
    if (req.headers.has('X-Skip-Interceptor') && req.headers.get('X-Skip-Interceptor') === 'true') {
      return next.handle(req);
    }


    // Clone the request and add the bearer token to the headers
    const authReq = req.clone();

    const userData = JSON.parse(localStorage.getItem('userData'));

    const modifiedBody = { ...authReq.body, user_id: userData.id };
    console.log(modifiedBody);
    const modifiedReq = authReq.clone({ body: modifiedBody });

    // Pass the modified request on to the next handler
    return next.handle(modifiedReq);
  }
}
