import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {User} from '../factory/models/authModel';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private isLoggedOn: boolean;
  private user: User;

  constructor(private router: Router) {
    this.user = JSON.parse(localStorage.getItem('userData'));
  }

  canActivate(): boolean {
    if (!!!this.user) {
      // User is not authenticated, redirect to login page
      this.router.navigate(['/auth/login']);
      return false;
    }

    return true;
  }
}
