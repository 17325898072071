import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class EmployeeRecordService {
  constructor(private _http: HttpClient,
  ) {

  }
  getEmployeeList(data: any): Observable<any> {
    return this._http.get(`${environment.base_url}/employee-data/list`, {params: data});
  }

  getRegisteredEmployee(data: any): Observable<any> {
    return this._http.get(`${environment.base_url}/auth/user/list`, {params: data});
  }
  getEmployeeListByDept(data: any): Observable<any> {
    return this._http.get(`${environment.base_url}/employee-data/list`, {params: data});
  }

  applyForLeave(data: any) {
    return this._http.post(`${environment.base_url}/employee-leave/create`, data, {observe: 'response'});
  }

  getEmployeeDetail(userId: number) {
    return this._http.get(`${environment.base_url}/employee-data/info/${userId}`);

  }

  makeHead(data) {
    const headers = new HttpHeaders({'X-Skip-Interceptor': 'true'});
    return this._http.post(`${environment.base_url}/employee-data/make-head`, data, {observe: 'response', headers});
  }
  selectHR(data) {
    const headers = new HttpHeaders({'X-Skip-Interceptor': 'true'});
    return this._http.post(`${environment.base_url}/employee-data/select-hr`, data, {observe: 'response', headers});
  }

  toggleEmployeeLogin(payload) {
    const headers = new HttpHeaders({'X-Skip-Interceptor': 'true'});
    return this._http.post(`${environment.base_url}/employee-data/toggle-login`, payload, {observe: 'response', headers});
  }

  toggleEmployeeLoginGet() {
    const headers = new HttpHeaders({'X-Skip-Interceptor': 'true'});
    return this._http.get(`${environment.base_url}/employee-data/toggle-login`, {headers});

  }
  terminateEmployee(payload){
    const headers = new HttpHeaders({'X-Skip-Interceptor': 'true'});
    return this._http.post(`${environment.base_url}/employee-data/update`, payload, {observe: 'response', headers});
  }

  updateEmployeeData(payload: any){
    const headers = new HttpHeaders({'X-Skip-Interceptor': 'true'});
    return this._http.post(`${environment.base_url}/employee-data/update`, payload, {observe: 'response', headers});
  }
}
