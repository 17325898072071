import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import {AuthGuard} from './auth-guard/auth-guard';


const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], },
  { path: 'auth',  loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  { path: 'appraisal', loadChildren: () => import('./appraisal/appraisal.module').then(m => m.AppraisalModule), canActivate: [AuthGuard], },
  { path: 'employee', loadChildren: () => import('./employee-formdata/employee-formdata.module').then(m => m.EmployeeFormdataModule), canActivate: [AuthGuard], },
  { path: 'employee-record', loadChildren: () => import('./employee-record/employee-record.module').then(m => m.EmployeeRecordModule), canActivate: [AuthGuard], },
  { path: 'employee-leave-record', loadChildren: () => import('./employee-leave-record/employee-leave-record.module').then(m => m.EmployeeLeaveRecordModule), canActivate: [AuthGuard], },
  { path: 'employee-leave-request', loadChildren: () => import('./employee-leaverequest/employee-leaverequest.module').then(m => m.EmployeeLeaverequestModule), canActivate: [AuthGuard], },
  { path: 'general-pages', loadChildren: () => import('./general-pages/general-pages.module').then(m => m.GeneralPagesModule) },
  { path: 'error-pages', loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
